<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{ inputs.language === 'en' ? 'How can we define ' : 'Comment la constante ' }}
        <stemble-latex content="$\text{pK}_\text{a}$" />
        {{
          inputs.language === 'en'
            ? ' in terms of pH?'
            : ' peut-elle être définie par rapport au pH?'
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'pH at which the acid-base reaction is no longer in equilibrium',
          value: 'notEquilibrium',
        },
        {
          text: 'pH at which the concentration of the acidic form equals the concentration of the conjugate base form',
          value: 'concentration',
        },
        {
          text: 'pH at which all the molecules are in the neutral acidic form',
          value: 'neutralAcidicForm',
        },
        {text: 'pH of an acidic solution', value: 'acidicSolution'},
        {
          text: 'pH at which all the molecules are in the conjugate base form',
          value: 'conjugateBaseForm',
        },
      ],
      optionsFr: [
        {
          text: 'pH auquel la concentration du forme acide est égale à la concentration du forme de base conjuguée',
          value: 'concentrationFr',
        },
        {
          text: 'pH auquel toutes les molécules sont sous forme de base conjuguée',
          value: 'conjugateBaseFormFr',
        },
        {text: "pH d'unes solution acide", value: 'acidicSolutionFr'},
        {
          text: "pH auquel toutes les molécules song sous forme d'acide neutre",
          value: 'neutralAcidicFormFr',
        },
        {
          text: "pH auquel la réaction acide-base n'est plus à l'équilibre",
          value: 'notEquilibriumFr',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
